$(document).ready(function() {
    let number = 0;
    function bindPictureUploadEvent(idx) {
        $(`#sortpicture${idx}`).on('change', function(e) {
            var file_data = e.currentTarget.files[0]; 
            
            if (file_data.size > 50 * Math.pow(2, 20) /* 50 MB */) {
                showMessageBox('danger', msg['ERR_FILE_TOO_LARGE'].replace(':size', '50 MB'));
                $(this).val(null);
            }
            else {
                var form_data = new FormData();                  
                form_data.append('file', file_data);
                form_data.append('type', 'einside');
                $.ajax({
                    url: 'https://ftp.dev.zero11.net/storage/einside/up.php', // point to server-side PHP script 
                    dataType: 'text',  // what to expect back from the PHP script, if anything
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form_data,                         
                    type: 'post',
                    success: function(php_script_response) {
                        const data = JSON.parse(php_script_response);
                        if (data.status == 'OK') {
                            $(`#upload-div-${idx}`).addClass('hide');
                            $(`#image-uploaded-${idx}`).removeClass('hide');
                            $(`#link-image-${idx}`).val(data.link);
                            number++;
                        } else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    },
                    error: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                });
            }
        })
    }
    $('[id^="sortpicture"]').each(function() {
        let idx = $(this).attr('id').replace('sortpicture', '');
        bindPictureUploadEvent(idx);
    });
});

function contactFormRecaptchaCallback() {
    $('#contact-form [type="submit"], #vat-relief-form [type="submit"]').prop("disabled", false);
}

function contactFormRecaptchaExpiredCallback() {
    $('#contact-form [type="submit"], #vat-relief-form [type="submit"]').prop("disabled", true);
}

function resetCaptcha() {
    grecaptcha.reset();
}