$(document).ready(function() {
    'use strict';

    $('#copyLink').on('click', function() {
        var ret = copyTextToClipboard($('#linkToCopy').val())
        if (ret) {
            $('#copyLink').html('Link copiato!')
        }
    })

    $('#user-update-data-form').validate({
        rules: {
            firstname: 'required',
            lastname: 'required',
            email: {
                required: true,
                email: true
            }
        },
        submitHandler: form => submitUserUpdateForm(form, ['firstname', 'lastname', 'email']),
    });

    $('#user-update-firm-form').validate({
        rules: {
            company: 'required'
        },
        submitHandler: form => submitUserUpdateForm(form, ['company', 'pec', 'sdi', 'note']),
    });

    $('#user-update-contacts-form').validate({
        submitHandler: form => submitUserUpdateForm(form, ['d_cel', 'd_cel_prefix', 'website', 'skype']),
    });

    $('#user-update-dispatch-form').validate({
        submitHandler: form => submitUserUpdateForm(form)
    });

    $('#user-update-invoice-form').validate({
        rules: {
            cfpiva: 'required'
        },
        submitHandler: form => submitUserUpdateForm(form)
    });

    function submitUserUpdateForm(form, fields=[]) {
        const $form = $(form);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function() {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                    fields.forEach(name => {
                        let val = $form.find(`[name="${name}"]`).val();
                        $(`[name="${name}"]`).each(function() {
                            $(this).val(val);
                        });
                    });
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function() {
                $form.find(':input').prop('disabled', false);
            }
        });
    }

    $('#password-change-form').validate({
        rules: {
            password1: {
                required: true,
                minlength: 8
            },
            password2: {
                equalTo: '#password1'
            }
        },
        messages: {
            password1: {
                minlength: msg['ERR_MINLENGTH'].replace(':length', '8')
            },
            password2: {
                equalTo: msg['ERR_CONFIRMPASSWORD']
            }
        },
        submitHandler: function(form) {
            const $form = $(form);
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function(data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORDUPDATED']);
                    }
                    else {
                        showMessageBox('danger', msg[data.errors.message] || msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function() {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function() {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });

    $('#profile-consent-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents':[]
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function(data) {

            },
            success: function(data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });
});